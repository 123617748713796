import React from "react"
import { graphql } from "gatsby"

import '../css/image-hero-v2.css';

const ImageHero = ({ section, bgimage, image, title, buttons }) => {
  var divStyle = {};

 
    divStyle.backgroundImage = 'url(' + bgimage.localFile.publicURL + ')';
  

  return (
    <div className="container defaultHero imageHero" style={divStyle}>
      <div className="grid smallGrid">
        <div className="col-sm-12">
          <div className="text-wrapper">        
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <MyButtons buttons={buttons}></MyButtons>
          </div>
          <div className="image-wrapper">
            { image && 
              <img src={image.localFile.publicURL} alt={image.altText} className="flex-img" />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const MyButtons = ({ buttons }) => {
  return (
    <div>
      {buttons && buttons.length > 0 && (
        <div>
          <ul>
            {buttons.map((button, index) => (
              <li key={index}>
                <button className={button.buttonType} >{button.buttonLabel}</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export { MyButtons }; // Exporting MyButtons as a named export


export default ImageHero
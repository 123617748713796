import React from "react";
import { Link } from "gatsby";

import '../css/two-column.css';

const TwoColumn = ({ content, section }) => {
  
  return (
    <div className="two-column">
      <div className="left-col">
        {content.leftImage && (
          <div className={`imageWrapper ${content.imagePosition}`}>
            <img className={section} src={content.leftImage.localFile.publicURL} alt={content.leftImage.altText} />
          </div>
        )}
        <div className="text-wrapper">
          <h2 className={`headline ${content.headlinePosition}`}>{content.leftHeadline}</h2>
          <div className={`content ${content.copyPosition}`} dangerouslySetInnerHTML={{ __html: content.leftCopy }} />
          <div className="button-wrapper">
          {content.leftButtons && (
          <>
              {content.leftButtons.map((button, index) => (
                <Link key={index} to={button.link.url} target={button.link.target}>
                  <button className={button.buttonType}>
                    {button.buttonLabel}
                  </button>
                </Link>
              ))}
            </>
          )}
          </div>  
        </div>
      </div>
      <div class="right-col">
          {content.rightImage && (
          <div className={`imageWrapper ${content.imagePosition}`}>
            <img className={section} src={content.rightImage.localFile.publicURL} alt={content.rightImage.altText} />
          </div>
         )}
        <div className="text-wrapper">
          <h2 className={`headline ${content.headlinePosition}`}>{content.rightHeadline}</h2>
          <div className={`content ${content.copyPosition}`} dangerouslySetInnerHTML={{ __html: content.rightCopy }} />
          <div className="button-wrapper">
          {content.rightButtons && (
          <>
              {content.rightButtons.map((button, index) => (
                <Link key={index} to={button.link.url} target={button.link.target}>
                  <button className={button.buttonType}>
                    {button.buttonLabel}
                  </button>
                </Link>
              ))}
            </>
          )}
          </div>  
        </div>
      </div>
    </div>
  );
}

export default TwoColumn;

import React from "react"
import { graphql } from "gatsby"

import '../css/hurricane.css';

const Hurricane = ({ content }) => {
  console.log(content);

  return (
    <div className="hurricane-wrapper">
      <div className="two-col">
        <div className="left-col">
          {content.himage && (
            <div>
              <img src={content.himage.localFile.publicURL} alt={content.himage.altText} />
            </div>
          )}
          
          <div dangerouslySetInnerHTML={{ __html: content.hurricaneContent }} />
        </div>
        <div className="right-col">
          <div className="intro-wrapper">
            <h4 className="superHL">{content.hsuperHeadline}</h4>
            <h2 className="hl">{content.hheadline}</h2>
            <div dangerouslySetInnerHTML={{ __html: content.introText }} />
          </div>
          <div className="checklist-wrapper">
            {content.checklists?.length > 0 && (
              content.checklists.map((item, index) => (
                <a href={item.pdf.localFile.publicURL} target="_blank" className="checklist-wrapper-link">
                <div key={index} className="checklist-item">
                  <img src={item.imagePreview.localFile.publicURL} alt={item.imagePreview.altText} />
                  <h3 className="item-title">{item.title}</h3>
                </div></a>
              ))
            )}
          </div>
  
          <div className="outro-wrapper">
            <div dangerouslySetInnerHTML={{ __html: content.outroText }} />
          </div>
        </div>
      </div>
      <div className="cta">
        <div dangerouslySetInnerHTML={{ __html: content.hcta }} />
      </div>
    </div>
  );
};

export default Hurricane

import React from "react"
import { graphql } from "gatsby"

import '../css/section-hero.css';

const SectionHero = ({ section, title, header}) => {
  
  var divStyle = {}; 
    divStyle.backgroundImage = 'url(' + header.localFile.publicURL + ')';

  return (
    <div className="container defaultHero sectionHero" style={divStyle}>
      <div className="grid smallGrid">
        <div className="col-sm-12">
          <div className="text-wrapper">
            <h3 className="superHL">{section}</h3>        
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
      </div>
    </div>
  )
}



export default SectionHero
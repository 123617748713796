import styled from 'styled-components';
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import React, { Component } from "react";
import GravityFormForm from 'gatsby-plugin-gravity-forms-ignitro';

import '../css/contact-form.css';

import Layout from "../layouts";
import Head from "../components/head";
import ImageHero from "../components/image-hero-v2";
import SectionHero from "../components/section-hero";
import TextImage from "../components/text-image";
import FullCopy from "../components/full-copy";
import ThreeColumn from "../components/three-column-v2";
import TwoColumn from "../components/two-column";

import Articles from '../components/articles';
import QuoteSlider from '../components/quote-slider';

import Hurricane from '../components/hurricane';

import YoastSeoParts from '../fragments/seo';

class PageTemplate extends Component {
  render() {
    const page = this.props.data.wpPage;
    const { seo } = page;
    const { imageHero, pageOptions } = page.template;
    const theContent = page.template.templateLandingPage.content;
    const articles = this.props.data.allWpPost.nodes;
    const section = pageOptions.section;

    const sectionHeader = this.props.data.wp.acfOptionsHeader.themeOptionsHeader.resourceCenterHeader;
    const hurricane = page.template.hurricane;

    console.log(theContent);
    console.log(articles);
    console.log(sectionHeader);

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />
        {
          imageHero && imageHero.backgroundImage && imageHero.frontImage && imageHero.headline ? (
            <ImageHero
              bgimage={imageHero.backgroundImage}
              image={imageHero.frontImage}
              title={imageHero.headline}
              buttons={imageHero.buttons}
            />
          ) : (
            <SectionHero section={section} title={page.title} header={sectionHeader} />
          )
        }
        <div>
          <div className="container">

            <div>
              <Hurricane content={hurricane}/>
            </div>


            {theContent?.length > 0 ? (
              theContent.map((item, index) => {
                switch (item.__typename) {
                  case 'WpTemplate_LandingPage_Templatelandingpage_Content_Textimage':
                    return (
                      <div key={index} className={`item-${index}`}>
                        <TextImage content={item} section={section} />
                      </div>
                    );
                  case 'WpTemplate_LandingPage_Templatelandingpage_Content_FullCopy':
                    return (
                      <div key={index} className={`item-${index}`}>
                        <FullCopy content={item} section={section} />
                      </div>
                    );
                  case 'WpTemplate_LandingPage_Templatelandingpage_Content_ThreeColumn':
                    return (
                      <div key={index} className={`item-${index}`}>
                        <ThreeColumn content={item} section={section} />
                      </div>
                    );
                  case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn':
                    return (
                      <div key={index} className={`item-${index}`}>
                        <TwoColumn content={item} section={section} />
                      </div>
                    );
                  case 'WpTemplate_LandingPage_Templatelandingpage_Content_QuoteSlider':
                    return (
                      <div key={index} className={`item-${index}`}>
                        <QuoteSlider testimonials={item.largeTestimonialSlider} section={section} />
                      </div>
                    );
                  case 'WpTemplate_LandingPage_Templatelandingpage_Content_Articles':
                    console.log(item.articles.id);
                    let articleLink = item.articles?.wpURI || '';
                    return (
                      <div key={index} className={`item-${index}`}>
                        <Articles
                          articlesCategoryId={item.articles.id}
                          title={item.articleTitle}
                          posts={articles}
                          link={articleLink}
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              })
            ) : (
              null
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default PageTemplate;

PageTemplate.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.object.isRequired,
    allWpPost: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query($id: String!, $articlesCategoryId: String = "//") {
    wpPage(id: { eq: $id }) {
      title
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_LandingPage {
          hurricane {
              himage{
                altText
                localFile {
                  publicURL
                }
              }
              hurricaneContent
              hsuperHeadline
              hheadline
              introText
              outroText
              hcta
              checklists {
                title
                imagePreview {
                  altText
                  localFile {
                    publicURL
                  }
                }
                pdf {
                  link
                  localFile {
                    publicURL
                  }
                }
              }
            }
          templateLandingPage {
            content {
              __typename
              ... on WpTemplate_LandingPage_Templatelandingpage_Content_Textimage {
                imagePosition
                image {
                  altText
                  localFile {
                    publicURL
                  }
                }
                superHeadline
                headline
                content
                ctaButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
              }
              ... on WpTemplate_LandingPage_Templatelandingpage_Content_FullCopy {
                headlinePosition
                headline
                copyPosition
                copy
                buttons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
              }
              ... on WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn {
                leftHeadline
                leftCopy
                leftImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                leftButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
                rightHeadline
                rightCopy
                rightImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                rightButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
              }
              ... on WpTemplate_LandingPage_Templatelandingpage_Content_ThreeColumn {
                leftHeadline
                leftCopy
                leftImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                leftButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
                middleHeadline
                middleCopy
                middleImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                middleButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
                rightHeadline
                rightCopy
                rightImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                rightButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
              }
              ... on WpTemplate_LandingPage_Templatelandingpage_Content_QuoteSlider {
                largeTestimonialSlider {
                  ... on WpTestimonial {
                    id
                    content
                    cptSingleTestimonial {
                      firstName
                      lastName
                      position
                      testimonial
                      signature {
                        localFile {
                          publicURL
                        }
                      }
                    }
                    featuredImage {
                      node {
                        localFile {
                          publicURL
                        }
                      }
                    }
                  }
                }
              }
              ... on WpTemplate_LandingPage_Templatelandingpage_Content_Articles {
                articleTitle
                articles {
                  wpURI
                }
              }
            }
          }
          pageOptions {
            section
          }
          imageHero {
            backgroundImage {
              altText
              localFile {
                publicURL
              }
            }
            frontImage {
              altText
              localFile {
                publicURL
              }
            }
            headline
            buttons {
              buttonLabel
              buttonType
              link {
                url
                target
              }
            }
          }
        }
      }
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { regex: $articlesCategoryId } } } } }
      sort: { order: DESC, fields: date }
      limit: 10
    ) {
      nodes {
        id
        title
        link
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
    wp {
      acfOptionsContact {
        themeOptionsContact {
          contactUsCopy
          caHeadquarters {
            address
            address2
            city
            faxNumber
            mapIframe
            phoneLink
            phoneText
            postalCode
            province
          }
          usHeadquarters {
            address
            address2
            city
            faxNumber
            mapIframe
            phoneLink
            phoneText
            state
            zip
          }
        }
      }
      acfOptionsHeader {
        themeOptionsHeader {
          resourceCenterHeader {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
